import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { createInstance } from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getInitialNamespaces } from "remix-i18next/client";

import { hydrateRoot } from "react-dom/client";
import React, { startTransition, StrictMode, useEffect } from "react";

import en from "~/locales/en";
import nl from "~/locales/nl";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

Sentry.init({
  dsn: "https://e8956798c804a8bea8484a4140d8f45f@o4508252318007296.ingest.de.sentry.io/4508257486569552",
  tracesSampleRate: 1,
  environment: window.ENV.ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

const GTM_ID = "GTM-MLWHPPSF";

let gtmScriptAdded = false;

function addGtmScript() {
  if (gtmScriptAdded || window.ENV.ENVIRONMENT === "local") {
    return;
  }

  // Dynamically execute the GTM script in the head
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", GTM_ID);

  // GTM noscript for the body as a fallback
  const noscript = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-MLWHPPSF";
  iframe.height = "0";
  iframe.width = "0";
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstChild);

  gtmScriptAdded = true; // Set the flag to true after adding GTM script and noscript
}
function App() {
  useEffect(() => {
    addGtmScript();
  }, []);

  return <RemixBrowser />;
}
async function hydrate() {
  let instance = createInstance()
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector); // Setup a client-side language detector
  // .use(Backend); // Setup your backend
  await instance.init({
    supportedLngs: ["nl", "en"],
    fallbackLng: "en",
    react: { useSuspense: false },
    ns: getInitialNamespaces(),
    detection: { order: ["htmlTag"], caches: [] },
    resources: { en: { translation: en }, nl: { translation: nl } },
    interpolation: { escapeValue: false },
  });

  startTransition(() => {
    // const [theme] = useTheme();
    hydrateRoot(
      document,

      <StrictMode>
        <I18nextProvider i18n={instance}>
          <GoogleReCaptchaProvider
            reCaptchaKey={window.ENV.GOOGLE_RECAPTCHA_CLIENT}
            language={instance.language}
          >
            <App />
          </GoogleReCaptchaProvider>
        </I18nextProvider>
      </StrictMode>
    );
  });
}
// if the browser supports SW (all modern browsers do it)
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     // we will register it after the page complete the load
//     navigator.serviceWorker.register("/sw.js");
//   });
// }
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

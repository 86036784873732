export default {
  greeting: "Hello",
  navItems: {
    home: "Home",
    statistics: "Team Stats",
    playerPerformance: "Player Performance",
    matchPerformance: "Match Performance",
    playerPerformanceOverview: "Overview",
    playerPerformanceGoals: "Goals",
    playerPerformanceAssists: "Assists",
    playerPerformanceAwards: "Awards",
    minutesPlayed: "Minutes Played",
    playerPerformanceDistanceCovered: "Distance Covered",
    attendance: "Attendance",
    team: "Manage Team",
    opponents: "Opponents",
    scheduling: "Schedule",
    media: "Media",
    settings: "Team settings",
    match: "Matches",
    training: "Training",
    matchAttendance: "Match attendance",
    trainingAttendance: "Training attendance",
    matchesUpcoming: "Matches",
    trainingUpcoming: "Training",
  },

  tempMessageLinkLabel: "Learn more",
  tempMessage:
    "Enjoy <2>Homebase Beta for free</2> while we are working on <2>Mingle Essentials</2>",

  watchMingleReplayLabel: "Watch Replay",
  title: "Sign in to Homebase",
  introReplayText: "Mingle replay out now - log in & watch yours!",
  introText:
    "Homebase is a desktop app for Mingle Sport power users. More stats, media and team management options.",
  introTextSub: "New? Create an account.",

  labelEmail: "Email",
  placeholderInputEmail: "name@mail.com",
  labelPassword: "Password",
  placeholderInputPassword: "Password",
  linkForgotPassword: "Forgot password?",
  buttonLogin: "Log in",
  buttonSignInApple: "Log in with Apple",
  buttonSignInGoogle: "Log in with Google",
  buttonSignUpApple: "Sign up with Apple",
  buttonSignUpGoogle: "Sign up with Google",
  protectedByReCaptcha: "This site is protected by reCAPTCHA and the Google",
  protectedByReCaptchaPrivacy: "Privacy Policy",
  protectedByReCaptchaPrivacyTerms: "Terms of Service",
  protectedByReCaptchaPrivacyTermsApply: "apply",
  Goalkeeper: "Goal keeper",
  Centreback: "Centre back",
  Leftback: "Left back",
  Rightback: "Right back",
  DefensiveMidfielder: "Defensive Midfielder",
  CentralMidfielder: "Central Midfielder",
  LeftMidfielder: "Left Midfielder",
  RightMidfielder: "Right Midfielder",
  AttackingMidfielder: "Attacking Midfielder",
  LeftWinger: "Left Winger",
  RightWinger: "Right Winger",
  Striker: "Striker",
  loginLoading: "Checking your credentials...",

  titleModalTeamIsArchived: "Team is Archived",
  bodyModalTeamIsArchived:
    " This team has been archived and therefore it is not possible to create matches & trainings. You can unarchive the team in the team settings.",

  titleModalAdminRights: " rights needed",
  bodyModalAdminRights:
    "You don’t have rights to use this feature. Ask your team admin to get rights.",

  titleModalBoostLevelRights: "Team boost needed",
  bodyModalBoostLevelRights:
    "You need a team boost to edit this feature for your team. Buy a team boost in the app.",
  linkLabelBoostLevelRights: "What's a team boost?",

  titleModalSeasonNotAvailable: "No future season available",

  bodyModalSeasonNotAvailable:
    "There is no next season available yet. Contact support to get your season started. ",
  buttonModalSeasonNotAvailableContact: "Contact support",
  titleModalTransferConfirm: "Create your new team?",
  bodyModalTransferConfirm:
    "You are about to create a new team for the next season. \n" +
    "\n" +
    "Don’t worry your current season and team will not get lost.",
  buttonModaTransferConfirmCreate: "Confirm",

  titleModalLogout: "Are you sure you want to sign out?",
  buttonModalLogoutLabel: "Sign out",

  onBoardingPages: {
    labelButtonSignUp: "Sign up",
    orSignUpSignUp: "Or Sign-up with e-mail",
    startTitle: "Create your team",
    welcomeTitle: "Welcome to Mingle Sport",
    welcomeBody1: "We're a better app for your football team.",
    welcomeBody2:
      "Track and compare stats. Share match content. Manage your team.",
    startSubTitle: "Create your team",
    labelDateOfBirth: "Date of birth",
    labelPreferredPosition: "Preferred position",
    roleTitle: "What is your role?",
    roleBody: "Select the most important one if you have multiple.",
    followPlayersTitle: "Follow your favourite players",
    followPlayersBody:
      "Download the app and search for your favourite team or player",
    followLearnMoreButtonLabel: "How to follow a team",
    followPlayerLearnMoreButtonLabel: "How to follow a player",
    teamSetupTitle: "Set up your team",
    joinTeamTitle: "Join a team",
    joinTeamsBody: "Download the app and enter your team code",
    joinTeamLearnMoreButtonLabel: "How to join a team",

    joinTeamButtonLabel: "Join",
    setUpTeamTitle: "Set up my team",
    setUpTeamButtonLabel: "Set up ",
    setUpTeamFanButtonLabel: "I'm a fan and want to follow a team",

    teamSetupBody:
      "Schedule your matches. Create your line-ups. Manage your team.",
    whatToImproveTitle: "What do you want to improve?",
    whatToImproveBody: "Select as many as you want.",
    whatToImproveBlock1Title: "Team motivation",
    whatToImproveBlock2Title: "Attendance",
    whatToImproveBlock3Title: "Fan Engagement",
    whatToImproveBlock4Title: "Performance",
    teamLevelTitle: "What is your team level?",
    teamLevelBody: "We're an app for every team.",
    labelTeamGender: "Gender",
    labelTeamAgeGroup: "Age group",
    labelTeamLevel: "Team level",
    labelSelectTeamGender: "Select gender",
    labelSelectTeamAgeGroup: "Select age group",
    labelSelectTeamLevel: "Select team level",
    teamLocationCountryLabel: "Country",
    teamLocationTitle: "What is your home location?",
    teamLocationBody:
      "You can change your location for every match and training.",
    whatSportTitle: "What sport do you play?",
    whatSportBody: "Select the sport you are creating a team for",
    inviteShareTitle: "Invite members",
    inviteShareBody:
      "You can let team members join with a unique team link. Or, add players yourself first. ",
    matchSetupTitle: "Your match setup",
    matchSetupBody: "Select your preferred formation",
    labelPlayers: "Players",
    labelPeriods: "Periods",

    shareTeamInviteTitle: "Share invite link",
    shareTeamInviteButtonLabel: "Share",
    addPlayersTitle: "Add players",
    addPlayersButtonLabel: "Add players",
    invitePlayersTitle: "Add players",
    invitePlayersBody:
      "When joining players can claim their names. You can also leave them unclaimed.",
    shareTitle: "Share your team link",
    shareBody:
      "Players, staff and team support (parents or others) can join the team by using the team join link.",
    teamInvite: {
      getAccess: "Get acces to your team!",
      clickCode: "click code to copy!",
      clickUrl: "Copy link!",
      copied: "copied",
      teamLink: "this team link",
      scanQr:
        "Scan the qr-code below to open the team page in the Mingle app or use",
      downLoadApp:
        "Download the Mingle Sport app & use the team code below to get access to your team!",
    },
    createMatchTitle: "Create your match",
    createMatchBody: "Choose your opponent, time and location.",
    nextMatchTitle: "Your next match",
    nextMatchBody: "Setup your first match on Mingle Sport",
    matchCreateSuccessTitle: "Match = scheduled!",
    matchCreateSuccessBody: "What can you do with matches on Mingle Sport? ",
    matchCreateSuccessBody1: "Know who's coming",
    matchCreateSuccessBody2: "Transportation needs",
    matchCreateSuccessBody3: "Line-up & Scorekeeping",
    matchCreateSuccessBody4: "Share with followers",
    matchCreateSuccessBody5: "Vote for different MVPs",
    buttonCreateAnotherMatch: "Create another match",
    trainingHowOftenTitle: "Training",
    trainingHowOftenBody:
      "How often do you have practice sessions with your team?",

    trainingCreateSuccessTitle: "Training = planned!",
    trainingCreateSuccessBody: "What can you do with practice sessions? ",
    trainingCreateSuccessBody1: "Know who's coming",
    trainingCreateSuccessBody2: "Understand reasons for no-shows",
    trainingCreateSuccessBody3: "Spot attendance trends",
    trainingCreateSuccessBody4: "Motivate with rewards",
    buttonCreateAnotherTraining: "Create another training",

    downloadTrialTitle: "Download the app",
    downloadTrialBody:
      "When joining players can claim their names. You can also leave them unclaimed.",

    teamLeagueTitle: "Select your league",
    teamLeagueBody: "Only for countries with different leagues.",
    enterTeamTitle: "Team name",
    enterTeamBody: "",
    organizationTitle: "Search your club",
    successTitle: "Your team has been created 😄",
    successTitleHomeBase: "",
    rolePlayerTitle: "Player",
    rolePlayerBody: "for a team. Any position possible.",
    roleFanTitle: "Fan",
    roleFanBody: "want to follow a player or team",
    roleParentTitle: "Parent",
    roleParentBody: "or others involved in the team",
    roleTrainerTitle: "Team staff",
    roleTrainerBody: "eg, trainer, coach or team manager.",
    buttonContinue: "Continue",
    buttonRequestLeague: "Request league +",

    buttonNamePlaceHolder: "Your name",
    buttonLastNamePlaceHolder: "Last name",
    genderMaleLabel: "Male",
    genderFemaleLabel: "Female",
    genderOtherLabel: "Other",
    genderMixedLabel: "Mixed",
    sportTypeFootball: "Soccer",
    sportTypeFutsal: "Futsal",
    selectCountryLabel: "Select country",
    subscriptionTitle: "Basic or Elevate?",
    subscriptionBody: "Choose your Team Boost Level",
    subscriptionBasicBody: "100% free. No traditional ads.",
    subscriptionEssentialsBody: "Level up your team",
    subscriptionAdvancedBody: "Track performance like a pro",
  },
  teamsPage: {
    title: "Manage team",
    buttonShareTeamLink: "Share team link",
    modalShareText: "Share team link",
    modalShareTeamPageText:
      "Check out the team page of {{teamName}} for match reports, team form and upcoming matches.",
    tabMembers: "Members",
    tabFollowers: "Followers",
    tabOpponents: "Opponents",
    buttonAddMemberLink: "Add member",
    buttonAddOpponentLink: "Add opponent",
    headerTableInfo: "Member",
    headerTableRole: "Role",
    headerTablePosition: "Position",
    headerTableStatus: "Status",
    headerTableMatchesPlayed: "Matches Played",
    headerTableMatchesPlayedMobile: "Matches",

    noTeamFound: "Homebase is for teams.",
    noTeamFoundLinkLabel: "Create your team",
    noTeamFoundBody1: "Create or join a team in the Mingle appS.",
    noTeamFoundBody2:
      "Homebase is a desktop app for Mingle Sport power users. More stats, media and team management options.",
    selectATeam: "Select a team",
    buttonLabelInvite: "Invite",
    buttonEditLabel: "Edit",
    buttonRemoveLabel: "Remove",
    buttonModalRemoveLabel: "Remove from team",
    buttonModalRemoveOpponentLabel: "Delete opponent",
    buttonModalCancelLabel: "Cancel",
    modalRemoveTitle: "Are you sure you want to {{name}} from the team?",
    modalSubTitle:
      "Removing {{name}} from the team will have consequences to their statistics.",

    modalRemoveOpponentTitle: "Delete opponent?",
    modalDeleteOpponentSubTitle: "Are you sure you want to remove {{name}}?",

    modalShareButtonlabel: "Share team link",
    editMemberModal: {
      title: "Edit member",
      introBody: "You can edit the role of this member",

      buttonEditMember: "Save changes",
      buttonCancel: "Cancel",
    },
    editPreUserModal: {
      title: "Edit member",
      introBody:
        "You can edit the fields of this (pre-user) member, this means the user claim this profile when they’re signing up to Mingle Sport",
      labelName: "Name *",
      labelEmail: "Email address",
      sendInviteLabel: "Send invite to user?",
      placeholderInputName: "John Doe",
      placeholderInputEmail: "E-mail address (not required)",
      buttonEditMember: "Save changes",
      buttonCancel: "Cancel",
    },

    addMemberModal: {
      title: "Add member",
      introBody:
        "This user will be added as a pre-user, this means the user needs to claim this profile when they’re signing up to Mingle Sport",
      labelName: "Name *",
      labelEmail: "Email address",
      placeholderInputName: "John Doe",
      placeholderInputEmail: "E-mail address (not required)",
      buttonAddMember: "Add member",
      buttonCancel: "Cancel",
    },
    inviteMemberModal: {
      title: "Invite member",
      introBody:
        "Send invite email to this member so they can claim their profile and sign up to Mingle Sport",
      labelEmail: "Email address",
      placeholderInputEmail: "Email address",
      buttonSendInvite: "Send invite",
      buttonCancel: "Cancel",
    },
  },
  modalWelcome: {
    modalWelcomeTitle: "Let’s get started!",
    modalWelcomeBody:
      "Download the Mingle Sport app. Or check out Homebase. Our desktop web app.",
  },
  transferPages: {
    title: "Season transfer",
    available: "available",
    titleIntro: "Setup your team for the next season",
    titleSubIntro: "You will create a new team. Add existing and new members",
    titleSubSubIntro:
      "(Don’t worry. You will not lose access to your current team)",
    setupYourNewTeam: "Setup your team for the new season",
    labelTransferTeam: "Transfer Team",
    alreadyTransferred: "Team is already transferred to the new season",
    stepTitle1: "Team info",
    stepTitle2: "Manage members",
    stepTitle3: "Inform everyone",
    unclaimed: "unclaimed",
    unclaimedTooltip: "Unclaimed users can not be transferred",
    labelLearnMore: "Learn more",
    teamNameAndLogo: "Team name & logo",
    forNextSeason: "for next season",
    labelPrevious: "Previous",
    labelNext: "Next",
    labelMembersSelected: " Members selected",
    labelSaveTeam: "Save team",
    labelSelectAll: "Select all?",
    labelSetupTeam: "Setup team",
    inviteMemberInfo:
      "You can invite new {{role}} using the invite link available when you create the team",
    inviteMemberPlayerInfo:
      "You can invite new {{role}} using the add member button on the manage team page after the team is transferred.",
    transferSucces: "Season transfer succesful",
    inviteMembers: "Invite new members with the information below",
    scanQr:
      "Scan the QR-code to join the team, enter the code below or share link",
    shareInviteLink: "Share invite link",
    labelDone: "Done",
  },
  mediaPage: {
    title: "Media",
    SubTitle: "Coming soon!",
    body1:
      "This feature is not live yet. We will keep you updated when you can start using Media.",
    body2:
      "With media you’ll be able to watch all media uploaded and upload your own footage. You can view the footage & review it with the team.",
    buttonLabelUpdated: "Keep me updated!",
  },
  schedulingPage: {
    title: "Schedule",
    SubTitle: "Coming soon!",
    body1:
      "This feature is not live yet. We will keep you updated when you can start using Scheduling.",
    body2:
      "With scheduling you will be able to plan & manage matches, trainings & events for your teams.",
    buttonLabelUpdated: "Keep me updated!",
  },
  statisticsPage: {
    title: "Stats",
    SubTitle: "Coming soon!",
    body1:
      "This feature is not live yet. We will keep you updated when you can start using Stats.",
    body2:
      "With stats you will be able to see all the stats from your teams and individual players.",
    buttonLabelUpdated: "Keep me updated!",

    noStatsFound: "No statistics found",
  },
  settingsPage: {
    title: "Team settings",
    titleOrganization: "Organization",
    titleLocation: "Location",
    buttonSave: "Save changes",
    teamDetailsTitle: "Team information",
    changImageLabel: "Change",
    changImageTitle: "Change team image",
    changeAvatarTitle: "Team avatar",
    changeAvatarSubTitle: "The avatar of the team that is visible",
    labelDropZone: "Drop new image here or click to browse",
    labelDropZoneOnlyOne: "(Only 1 JPG or PNG image)",
    labelDropZoneErrorSize:
      "Image size ({{size}}mb) exceeds limit , limit is 4mb`",
    labelOrganization: "Organization ",
    labelName: "Team name",
    labelClubName: "Club name",
    labelNameAbbreviation: "Team Abbreviation",
    archiveTeamTitle: "Archive team",
    archiveTeamSubTitle: "Season over? Archive your team.",
    archiveTeamButtonLabel: "Archive team",
    archiveTeamModalTitle: "Archive team?",
    archiveTeamModalSubTitle1: "Are you sure you want to archive ",
    archiveTeamModalSubTitle2:
      "You won’t be able to create matches or training events. Stats will be available for your archived team.",
    archiveTeamModalButtonLabel: "Archive team",

    unArchiveTeamTitle: "Unarchive team",
    unArchiveTeamSubTitle: "Re-activate your team.",
    unArchiveTeamButtonLabel: "Unarchive team",
    unArchiveTeamModalTitle: "Unarchive team?",
    unArchiveTeamModalSubTitle1: "Are you sure you want to unarchive",
    unArchiveTeamModalSubTitle2:
      "You will be able to create matches or training events. Stats will be available for your team.",
    unArchiveTeamModalButtonLabel: "Unarchive team",
    labelLeague: "League",
    labelSeason: "Season name",
    labelSeasonSingle: "Season",

    labelSeasonDate: "Season duration",
    teamProfileTitle: "Team profile",
    teamProfileSubTitle: "Describe your key team characteritics of ",
    labelTeamGender: "Gender",
    labelTeamAgeGroup: "Age group",
    labelTeamLevel: "Team level",
    labelSelectTeamGender: "Select gender",
    labelSelectTeamAgeGroup: "Select age group",
    labelSelectTeamLevel: "Select team level",
    genderMaleLabel: "Male",
    genderFemaleLabel: "Female",
    genderOtherLabel: "Other",
    genderMixedLabel: "Mixed",
    teamSocialsTitle: "Team Socials",
    teamSocialsSubTitle: "Add your social handles to share with others",
    teamAvatarTitle: "Team avatar",
    teamAvatarSubTitle: "The avatar of the team that is visible",
    locationTitle: "Location",
    locationSubTitle: "Home location of this team",
    noLocationTitle: "Unknown location",
    noLocationSubTitle: "There is no home location saved for this team",
    discard: "Discard",
  },
  claimAccount: {
    startPage: {
      title: "Is this you?",
      subTitle: "Claim this account & join the team",
      labelMatchesPlayed: "Matches played",
      labelGoalsScored: "Goals scored",
      labelAssistsGiven: "Assists given",
      partOfTeam:
        "You will be part of team <1>{{teamName}}</1> and ready to start using Mingle Sport.",
      buttonLabel: "Sign up and claim account",
      buttonLabelLoggedIn: "Claim account",
      notYourTeam: "Not you, but it is your team?",
      joinTeam: "Join the team",
    },

    successPage: {
      title: "Congrats, you’ve claimed your the {{name}} account",
      subTitle:
        "You’re now part of team <2>{{teamName}}</2> and ready to start using Mingle Sport.",
      downloadLabel: "Download the app",
      homebaseLabel: "Or check out your team details in",
    },
  },

  shotQualityPage: {
    signUp: "Sign up",
    title: "Watch and download your video",
    detailsTitle: "Shot Quality",
    detailsAccuracy: "accuracy",
    detailsSpeed: "speed",
    detailsPoints: "points",

    signUpButtonLabel: "Sign up",
    downLoadButtonLabel: "Download video",
    downLoadAppButtonLabel: "Download the Mingle Sport app",
  },
  signUpPage: {
    title: "Sign up",
    subTitle: "To claim this account & join the team",
    haveAccount: "Already have an account?",
    buttonLabelSignIn: "Sign in",
    labelInputFirstName: "First name",
    labelInputLastName: "Last name",
    labelInputEmail: "Email",
    labelInputPassword: "Password",
    labelButtonSignUp: "Sign Up",

    placeholderInputFirstName: "Type your first name here",
    placeholderInputLastName: "Type your last name here",
    placeholderInputEmail: "Type your email here",
    placeholderInputPassword: "Type your password here",
    labelCheckBoxPrivacyAndTerms:
      "I accept the Mingle Sport <2>Terms</2> and <6>Privacy Policy</6>.",
  },
  signInPage: {
    title: "Sign in",
    subTitle: "To claim your account & join the team",
    dontHaveAccount: "Don’t have a mingle sport account yet?",
    buttonLabelSignUp: "Sign up",
    labelInputEmail: "Email",
    labelInputPassword: "Password",
    placeholderInputEmail: "Type your email here",
    placeholderInputPassword: "Type your password here",
    labelButtonSignIn: "Sign in",
  },
  statsPages: {
    titlePlayerPerformance: "Match Performance",
    buttonMoreInfo: "More info",
    buttonAllPlayers: "All Players",
    titleMatchAttendance: "Attendance Leaderboard",
    titleReasonNotGoingAttendance: "Reasons for not coming",
    subTitleReasonNotGoingAttendance: "Reasons for not attending a match",
    titleTrainingReasonNotGoingAttendance: "Trainings not attending",
    subTitleTrainingReasonNotGoingAttendance:
      "Reasons for not attending a training",
    titleTrainingAttendance: "Training Attendance",
    titleAverageAttendance: "Average attendance",
    titleTeamStats: "Team stats",
    titleMinutesPlayed: "Minutes played",
    subTitleMinutesPlayed: "Average minutes played per match",
    titleGoals: "Goals",
    titleGoalsScored: "Goals scored",
    titleDistanceCovered: "Distance Covered",
    subTitleDistanceCovered: "Total distance covered per player",
    titleAwards: "Awards",
    titleMatchAwards: "Match Awards",
    titleAssists: "Assists",
    labelChangeMinutesPlayedSaved: "Save",

    titleTeamPerformance: "Team performance",
    matchRating: "Match rating",
    titleSpectators: "Spectators",
    titleEngagement: "Engagement",
    titleCheersComments: "Cheers & comments",
    titleGoalDifference: "Goal difference",
    totalScoredConceded:
      "In total {{totalScored}} goals were scored. {{totalConceded}} conceded",
    titleMatchWinRatio: "Match win ratio",
    titleTotalShare: "Total share of matches won",
    titleTotalWin: "Win",
    titleTotalDraw: "Draw",
    titleTotalLost: "Lost",
    titleGoalsPerMatch: "Goals per match",
    titleAveragePerMatch: " Average number of goals scored per match",
    titleTotalGoalsConceded: "Goals conceded",
    titleAverageGoalsConceded: "Average number of goals conceded per match",
    titleCleanSheets: "Clean sheets",
    titleMatches0Conceded: "Number of matches with 0 conceded goals",
    titleTeamForm: "Team form",
    titleResultsLastMatches: "Results of the last matches",
    titleLast30days: "Last 30 days",
    titleLast12Months: "Last 12 months",
    titlePast: "Finished",
    titleFuture: "Upcoming",
    titlePastFutureActivities: "activities",
    titlePastFutureMatches: "matches",
    titlePastFutureTrainings: "trainings",
    titlePlayersLeaderboard: "Players who attended most matches",
    titlePlayersLeaderboardTraining: "Players who attended most training",
    titlePlayersLeaderboardMinutesPlayed:
      "Players who have played the most minutes",
    titleInsights: "Insights",
    titleLearningsForTeam: "Learnings for the team",
    titleTrendLastTrainings: "Trend last 2 trainings",
    title100AttendanceTraining: "100% attendance training",
    titleTrendLastMatch: "Trend last 2 matches",
    title100AttendanceMatch: "100% attendance matches",
    titleTeamSizeTraining: "Team size per training",
    titleTeamSizeMatch: "Team size per match",

    graphTitleEngagement: "Engagement",
    graphTitleGoalDifference: "GD total",
    graphTitleGoalDifferencePerMatch: "GD per match",
    graphTitleSpectators: "Spectators",
    graphTitleMatchRating: "Match rating",
    graphTitleAverageMatchRating: "Average match rating",
    graphTitleMatchAttendance: "Match attendance",
    graphTitleTrainingAttendance: "Training attendance",

    tooltipAveragematchRating:
      "Your average match rating in the selected period",
    tooltipTotalViews:
      "Your total views in the selected period (this includes team members and the followers of team members)",
    tooltipAverageMatchAttendance:
      "The average match attendance of the team per match",
    tooltipAverageTrainingAttendance:
      "The average training attendance of the team per training",
    tooltipTotalEngagement:
      "Your total engagement (total of cheers & comments) in the selected period (this includes team members and the followers of team members)",
  },
  errorMessages: {
    errorCodeLogin:
      "The link expired or is invalid. Please follow the link from the app again. Or provide your login credentials above.",
    errorServerSignIn:
      "Could not login with the credentials provided. Please try again. Or reset your password by clicking the 'Forgot password?' link above.",
    errorServer: "An error occurred. Please try again later.",
    emailValid: "Email is not valid",
    emailUsernameValid: "Username is not valid it must be your login email",
    passwordValid: "Password is not valid",
    emailRequired: "Email is required",
    firstNameRequired: "Firstname is required",
    lastNameRequired: "Lastname is required",
    genderRequired: "Gender is required",
    fieldRequired: "This field is required",
    zipCode: "Zip code is not valid",
    agreeToTerms: "You must agree to the terms and privacy policy",
    nameRequired: "Name is required",
    teamNameRequired: "Team name is required",
    teamGenderRequired: "Gender is required",
    teamAgeGroupRequired: "Age group is required",
    teamLevelRequired: "Team level is required",
  },

  schedulePages: {
    title: "Schedule",
    createMatch: "Create match",
    editMatch: "Edit match",
    editTraining: "Edit training",

    edit: "Edit",
    createMatchFirst: "Create your match and plan this throughout the year",
    startTime: "Start time",
    arrivalTime: "Arrival time",
    duration: "Duration",
    date: "Date",
    info: "Info",
    labelOpponent: "Opponent",
    labelOpponentName: "Opponent name",
    labelOpponentNameAbbreviation: "Opponent abbreviation",
    occurrence: "Occurrence",
    labelAway: "Away",
    labelHome: "Home",
    labelAwayMatch: "Away match",
    labelHomeMatch: "Home match",
    thirtyMinutes: "30 min.",
    fourtyFiveMinutes: "45 min.",
    oneHour: "1 hour",
    oneHalfHour: "1,5 hour",
    twoHours: "2 hour",
    Never: "Single Occurrence",
    Week: "Repeat Weekly",
    TwoWeeks: "Repeat every 2 weeks",
    Month: "Repeat Monthly",

    createTraining: "Create training",
    createTrainingFirst:
      "Create your training and plan this throughout the year",
    matchLabel: "Match",
    trainingLabel: "Training",

    nextMatch: "Next match",
    nextMatchExample: "Next match (example)",
    nextMatchInfo: "  Date, home match",

    nextTraining: "Next training",
    nextTrainingExample: "Next training (example)",
    nextTrainingInfo: "Date, Tuesday training",

    blockTitleHomeAway: "Playing home or away?",
    blockTitleTeamOpponent: "Team & opponent",
    blockTitleTimeDate: "Time & date",
    blockTitleTrainingDetails: "Training title",
    blockTitleTeam: "Team",
    modalOpponentTitle: "Add Opponent",
    modalEditOpponentTitle: "Edit Opponent",
    modalOpponentSubTitle:
      "You can select the opponent while creating your matches",

    modalDeleteMatchTitle: "Delete match?",
    modalDeleteMatchSubTitle: "Are you sure you want to delete this match?",
    modalDeleteTrainingTitle: "Delete training?",
    modalDeleteTrainingSubTitle:
      "Are you sure you want to delete this training?",
    modalDeleteTrainingSeriesTitle:
      "Do you want to delete this single occurence or the whole series?",
    modalEditTrainingTitle: "Edit training?",
    modalEditTrainingSeriesTitle:
      "Do you want to edit this single occurence or the whole series?",
    modalDeleteLabelSingle: "Single occurence",
    modalDeleteLabelSeries: "Whole series",
    modalDeleteLabelCancel: "Cancel",
    modalDeleteLabelDelete: "Delete",
    modalAddOpponentButtonLabel: "Add opponent",
    modalEditOpponentButtonLabel: "Edit opponent",
    autoCompleteOrganization: "Search & Select organization (club)",
    autoCompleteSearch: "Search",
    trainingTitleValidationError: "Training title is required",
    matchOpponentNameValidationError: "Name is required",
    matchOpponentAbbreviationValidationError: "Abbreviation is required",
    organizationValidationError: "Organization is required",

    labelOpponentPlaceholder: "Type the name of the opponent",
    labelOpponentNameAbbreviationPlaceholder:
      "Type the abbreviation of the opponent",
    newOpponentTooltip: "Add new opponent",
    selectOpponent: "Select opponent",
    selectOpponentError: "Select an opponent",
    note: "Note",
    noteLabel: "Add a note",
    notePlaceholder: "E.g. gather at the club",
  },
  editUserModal: {
    title: "Edit details",
    introBody: "Update your basic information",
    labelName: "First Name",
    labelLastName: "Last Name",
    labelEmail: "Email address",
    accountRole: "Account role",
    labelPreferredPosition: "Preferred position",
    labelDateOfBirth: "Date of birth",
    buttonEditMember: "Save changes",
    buttonModalCancelLabel: "Cancel",
    labelGender: "Gender",
  },
  confirmProceedModal: {
    areYouSure: "Are you sure you want to leave this page?",
    areYouSureBody:
      "There are unsaved changes in the page which will be lost if you proceed.",
    labelYes: "Yes, go!",
    labelNo: "No, stay & save!",
  },
  toastMessages: {
    toastTeamTransSuccess: "Team has been transferred successfully.",
    toastTeamUpdateSuccess: "Team has been updated successfully.",
    toastUnArchiveSuccess: "Team has been unarchived successfully.",
    toastAttenadanceSuccess: "Attendace updated successfully.",
    toastSwitchSuccess: "Team switched successfully.",
    toastArchiveSuccess: "Team has been archived successfully.",
    toastMessageOpponentAddedTitle: "Opponent added",
    toastMessageOpponentAddedSubTitle: "Opponent is successfully added.",
    toastMessageMatchAddedTitle: "Match created",
    toastMessageMatchAddedSubTitle: "The match was created successfully",
    toastMessageMatchEditedTitle: "Match edited",
    toastMessageMatchEditedSubTitle: "The match was edited successfully",
    toastMessageMatchDeletedTitle: "Match deleted",
    toastMessageMatchDeletedSubTitle: "The match was deleted successfully",
    toastMessageTrainingAddedTitle: "Training created",
    toastMessageTrainingAddedSubTitle: "The training was created successfully",
    toastMessageTrainingEditedTitle: "Training edited",
    toastMessageTrainingEditedSubTitle: "The training was edited successfully",
    toastMessageTrainingDeletedTitle: "Training deleted",
    toastMessageTrainingDeletedSubTitle:
      "The training was deleted successfully",
    toastDeletedSuccess:
      "Team member {{name}} has successfully been removed from the team.",
    toastDeletedOpponentSuccess: "Opponent {{name}} was successfully removed.",
    toastAddedSuccess: "{{name}} has successfully been added to the team.",
    toastEditSuccess: "{{name}} has successfully been updated.",
    toastInviteSuccess: "An invite email has been send to {{email}}.",
    toastSuccess: "Thank you for your interest! We will keep you updated!",
    toastMessageMinutesPlayedUpdated:
      "Minutes played were successfully updated",
  },
};
